import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import callFetch from "helpers/callFetch";
function Sidebar() {
    const { t } = useTranslation();
    const [lastMessage, setLastMessage] = useState([]);
    const [authId, setAuthId] = useState('');
    const [nowDrop, setNowDrop] = useState(window.location.href.split('/')[3]);
    let navClassName = "nav-link";
    let activeClassName = "nav-link active";
    let dropdownClass = "collapse";
    let dropdownClassShow = "collapse show";

    return (
        <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 " id="sidenav-main">
            <div className="sidenav-header">
                <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav" />
                <a className="navbar-brand m-0" href="/">
                    <img src="/assets/img/logo.png" className="navbar-brand-img h-100" alt="main_logo" />
                </a>
            </div>
            <hr className="horizontal dark mt-0" />
            <div className="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
                <ul className="navbar-nav">
                    
                    <li className="nav-item">
                        <NavLink to="/dashboard" onClick={(e) => setNowDrop('dashboard')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-dashboard icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="sidenav-normal">{t('Dashboard')}</span>
                        </NavLink>
                    </li>

                    {Cookies.get('permissions').indexOf("employee-read") !== -1 ? (
                    <li className={"nav-item"}>
                        <a data-bs-toggle="collapse" href="#hr" className={nowDrop === 'hr' ? activeClassName : navClassName} aria-controls="hr" role="button" aria-expanded={nowDrop === 'hr'}>
                            <i className="fa-solid fa-users icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="nav-link-text ms-1">{t('Staff Management')}</span>
                        </a>
                        <div className={nowDrop === 'hr' ? dropdownClassShow : dropdownClass} id="hr">
                            <ul className="nav ms-4 ps-3">
                                {Cookies.get('permissions').indexOf("employee-read") !== -1 ? (
                                    <>
                                        <li className="nav-item">
                                            <NavLink to="/hr/employees" onClick={(e) => setNowDrop('hr')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Employees')}</span>
                                            </NavLink>
                                        </li>                                    
                                    </>
                                ) : <></>}
                            </ul>
                        </div>
                    </li>
                    ) : <></>}

                    {Cookies.get('permissions').indexOf("quotation-read") !== -1 ||
                    Cookies.get('permissions').indexOf("order-read") !== -1  ? (
                    <li className={"nav-item"}>
                        <a data-bs-toggle="collapse" href="#sales-distribution" className={nowDrop === 'sales-distribution' ? activeClassName : navClassName} aria-controls="sales-distribution" role="button" aria-expanded={nowDrop === 'sales-distribution'}>
                            <i className="fa-solid fa-bag-shopping icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            {/* <svg class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg> */}
                            <span className="nav-link-text ms-1">{t('Sales & Distribution')}</span>
                        </a>
                        <div className={nowDrop === 'sales-distribution' ? dropdownClassShow : dropdownClass} id="sales-distribution">
                            <ul className="nav ms-4 ps-3">
                                {Cookies.get('permissions').indexOf("quotation-read") !== -1 ? (
                                    <>
                                        <li className="nav-item">
                                            <NavLink to="/sales-distribution/quations" onClick={(e) => setNowDrop('sales-distribution')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Angebote')}</span>
                                            </NavLink>
                                        </li>                                    
                                    </>
                                ) : <></>}

                                {Cookies.get('permissions').indexOf("order-read") !== -1 ? (
                                    <>
                                        <li className="nav-item">
                                            <NavLink to="/sales-distribution/orders" onClick={(e) => setNowDrop('sales-distribution')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Auftrag')}</span>
                                            </NavLink>
                                        </li>                                    
                                    </>
                                ) : <></>}
                            </ul>
                        </div>
                    </li>
                    ) : <></>}
                    
                    {Cookies.get('permissions').indexOf("callcenter-read") !== -1 ? (
                    <li className={"nav-item"}>
                        <a data-bs-toggle="collapse" href="#call-center" className={nowDrop === 'call-center' ? activeClassName : navClassName} aria-controls="call-center" role="button" aria-expanded={nowDrop === 'call-center'}>
                            <i className="fa-sharp fa-solid fa-phone icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="nav-link-text ms-1">{t('Call Center')}</span>
                        </a>
                        <div className={nowDrop === 'call-center' ? dropdownClassShow : dropdownClass} id="call-center">
                            <ul className="nav ms-4 ps-3">
                                {Cookies.get('permissions').indexOf("callcenter-create") !== -1 ? (
                                <li className="nav-item">
                                    <NavLink to="/call-center/create" onClick={(e) => setNowDrop('call-center')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                        <span className="sidenav-normal">{t('New Call Center')}</span>
                                    </NavLink>
                                </li>   
                                ) : <></>}
                                
                                {Cookies.get('permissions').indexOf("callcenter-read") !== -1 ? (
                                <li className="nav-item">
                                    <NavLink to="/call-center/index" onClick={(e) => setNowDrop('call-center')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                        <span className="sidenav-normal">{t('Call Center')}</span>
                                    </NavLink>
                                </li> 
                                ) : <></>}
                            </ul>
                        </div>
                    </li>
                    ) : <></>}
                                        
                    <li className="nav-item d-none">
                        <NavLink to="/application-settings" onClick={(e) => setNowDrop('')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa fa-globe icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></i>
                            <span className="nav-link-text ms-1">Application Settings</span>
                        </NavLink>
                    </li>

                    
                </ul>
            </div>
        </aside>
    );
}

export default Sidebar;
